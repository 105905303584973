import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Helmet from "react-helmet"
import get from "lodash.get"
import { useLocation } from "@reach/router"

export default function Seo({ data, pageContext }) {
  const { pathname } = useLocation()
  return (
    <StaticQuery
      query={graphql`
        {
          site {
            siteMetadata {
              siteUrl
            }
          }
        }
      `}
      render={({ site }) => {
        const url = `${get(site, "siteMetadata.siteUrl", "")}${pathname}`
        const title = get(data, "meta_title")
        const description = get(data, "meta_description")
        const keywords = get(data, "meta_keywords")
        const image = get(data, "meta_image.url")
        const type = get(data, "type") || "website"
        return (
          <>
            <Helmet>
              <html
                lang={
                  (pageContext &&
                    pageContext.lang &&
                    pageContext.lang.split("-")[0]) ||
                  "en"
                }
              ></html>
              {title && <title>{title}</title>}
              {title && <meta property="og:title" content={title} />}
              {title && <meta name="twitter:title" content={title} />}
              <meta name="og:type" content={type} />
              <meta name="og:url" content={url} />
              <meta property="og:site_name" content="greatcontent" />
              <meta name="twitter:site" content="@_greatcontent_" />
              {description && <meta name="description" content={description} />}
              {description && (
                <meta property="og:description" content={description} />
              )}
              {description && (
                <meta name="twitter:description" content={description} />
              )}
              {image && <meta name="image" content={image} />}
              {image && <meta property="og:image" content={image} />}
              {image && <meta name="twitter:image" content={image} />}
              {keywords && <meta name="keywords" content={keywords} />}
              {keywords && <meta property="og:keywords" content={keywords} />}
              {keywords && <meta name="twitter:keywords" content={keywords} />}
              <meta name="twitter:card" content="summary_large_image" />{" "}
            </Helmet>
          </>
        )
      }}
    />
  )
}
