const linkResolver = (doc) => {
  let lang = "/"
  if (doc.lang && doc.lang !== "en-us") {
    lang = "/" + doc.lang.split("-")[0] + "/"
  }

  switch (doc.type) {
    case "page":
      if (doc.uid == "homepage") {
        return lang
      } else {
        return sanitize(`${lang}/${doc.uid}/`)
      }
    case "deep_dive":
      return sanitize(`${lang}/${doc.uid}/`)
    case "helpdesk":
      return sanitize(`${lang}/helpdesk/${doc.uid}/`)
    case "landing_page":
      return sanitize(`${lang}/${doc.uid}/`)
    case "page":
      if (doc.uid == "homepage") {
        return lang
      } else {
        return sanitize(`${lang}/${doc.uid}/`)
      }
    case "category":
      return sanitize(`${lang}/${doc.route_prefix}/${doc.uid}/`)
    case "tag":
      return sanitize(`${lang}/${doc.route_prefix}/${doc.uid}/`)
    case "post":
      return sanitize(`${lang}/${doc.uid}/`)
    default:
      break
  }
  return "/"
}

function sanitize(link) {
  return link.replace(/\/\//g, "/")
}

module.exports = { linkResolver }
