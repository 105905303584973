/* eslint-disable */

import React from "react"

export default function useIntersectionObserver({
  root,
  target,
  onIntersect = () => {},
  onLeave = () => {},
  threshold = 1.0,
  rootMargin = "0px",
  enabled = true,
}) {
  React.useEffect(() => {
    if (!enabled && !target.current) {
      return
    }

    const observer = new IntersectionObserver(
      (entries) =>
        entries.forEach((entry) => {
          entry.isIntersecting && onIntersect()
          !entry.isIntersecting && onLeave()
        }),
      {
        root: root && root.current,
        rootMargin,
        threshold,
      }
    )

    const el = target && target.current

    if (!el) {
      return
    }

    observer.observe(el)

    return () => {
      observer.unobserve(el)
    }
  }, [target.current, enabled])
}
