import React, { useEffect } from "react"
import BackToTopArrow from "./BackToTopArrow"
import customEvent from "./customEvent"
import Footer from "./Footer"
import Header from "./Header"

import Seo from "./SEO"

export default function Layout({
  transparentNavigation,
  scrollArrowAction,
  showMenu,
  children,
  distraction_free_mode,
  landingpage,
  seo,
  pageContext,
}) {
  useEffect(() => {
    function handleButtonClickEvents(e) {
      e.stopPropagation()
      if (
        e.target.classList.contains("primary-button") ||
        e.target.classList.contains("secondary-button") ||
        e.target.classList.contains("login_button")
      ) {
        let insideLink = e.target.querySelector("a")
        if (insideLink) {
          insideLink.click()
        }

        e.stopPropagation()
        customEvent(e)
      }
    }
    typeof window !== "undefined" &&
      window.addEventListener("click", handleButtonClickEvents)
    return () => {
      typeof window !== "undefined" &&
        window.removeEventListener("click", handleButtonClickEvents)
    }
  }, [])
  return (
    <>
      <Seo pageContext={pageContext} data={seo} />
      <div className="flex flex-col min-h-screen">
        {!distraction_free_mode && (
          <Header
            landingpage={!showMenu && landingpage}
            transparentNavigation={transparentNavigation}
            pageContext={pageContext}
          />
        )}
        <main className="flex-grow ">{children}</main>
        {!distraction_free_mode && (
          <BackToTopArrow action={scrollArrowAction} />
        )}
        {!distraction_free_mode && <Footer pageContext={pageContext} />}
      </div>
    </>
  )
}
