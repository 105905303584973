import React, { useState } from "react"
import get from "lodash/get"
import Collapse from "../Collapse"
import TextRender from "../TextRender"
import { FaChevronDown } from "react-icons/fa"
import { css } from "@emotion/core"
import tw from "twin.macro"
import cn from "classnames"
import kebabCase from "lodash/kebabCase"
export default function Accordion({ data }) {
  const items = data.items
  return (
    <div
      className="my-12 container label-id  "
      css={css`
        main &:last-of-type {
          padding-bottom: 50px;
        }
      `}
      data-label={data.slice_label}
    >
      <div
        className="prose"
        css={css`
          h3 {
            ${tw`text-2xl mb-8!`}
          }
        `}
      >
        <h3 id={kebabCase(get(data, "primary.title1.text"))}>
          {get(data, "primary.title1.text")}
        </h3>
      </div>
      {items &&
        items.map((item, index) => {
          return <Item key={index} item={item} />
        })}
    </div>
  )
}

function Item({ item }) {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <div className="mt-4">
      <div
        className="filter-shadow p-4  bg-light rounded  flex items-center justify-between cursor-pointer"
        onClick={() => {
          setIsOpen(!isOpen)
        }}
      >
        <span>{item.title1}</span>
        <FaChevronDown
          className={cn(
            "w-3 h-3 transition-transform duration-300 transform ease-in-out",
            {
              "rotate-180": isOpen,
              "rotate-0": !isOpen,
            }
          )}
        />
      </div>
      <Collapse isOpen={isOpen}>
        <div className="prose pt-3 px-4 max-w-none">
          <TextRender text={get(item, "text.raw")} />
        </div>
      </Collapse>
    </div>
  )
}
