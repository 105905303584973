import get from "lodash/get"

export default function ImageProps(image = {}) {
  const a = image.alt

  if (!a) {
    return {
      alt: "",
      title: "",
    }
  }

  let title = a.match(/title=\"(.+)\"/)
  if (typeof title == "object") {
    title = get(title, "[1]", "")
  }
  let alt = a.replace(/\[.+\]/, "")

  return {
    alt,
    title,
  }
}
