import React from "react"

export default function GoogleOff({ children }) {
  return (
    <>
      <div dangerouslySetInnerHTML={{ __html: "<!--googleoff: all-->" }}></div>
      {children}
      <div dangerouslySetInnerHTML={{ __html: "<!--googleon: all-->" }}></div>
    </>
  )
}
