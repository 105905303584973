import { graphql, useStaticQuery } from "gatsby"

export default function useLabels(lang) {
  const { allPrismicConfiguration } = useStaticQuery(graphql`
    {
      allPrismicConfiguration {
        nodes {
          lang
          data {
            categories
            category_route
            follow_us
            next
            prev
            read_more
            recent_post
            related_post
            tag_cloud
            tag_route
            category
            tag
          }
        }
      }
    }
  `)
  let labels = {}
  try {
    labels = allPrismicConfiguration.nodes.find((n) => n.lang == lang).data
  } catch (error) {}
  return labels
}
