import React from "react"
import get from "lodash/get"
import { css } from "@emotion/core"
import TextRender from "../TextRender"
import tw from "twin.macro"
import { getTitleFromRaw } from "../../templates/deep-dive"

export default function CtaText({ data }) {
  return (
    <div
      className="bg-light  py-16 my-16 label-id "
      data-label={data.slice_label}
      id={getTitleFromRaw(get(data, "primary.text.raw"), true)}
    >
      <div className="container">
        <div
          className="prose max-w-none"
          css={css`
            p {
              ${tw`text-lg text-black `}
            }
            .primary-button,
            .secondary-button {
              ${tw`text-lg text-black px-4 mr-3`}
            }
          `}
        >
          <TextRender text={get(data, "primary.text.raw")} />
        </div>
      </div>
    </div>
  )
}
