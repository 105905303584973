import { motion, useTransform, useViewportScroll } from "framer-motion"
import React from "react"
import { MdArrowUpward } from "react-icons/md"
import TextRender from "./TextRender"
import get from "lodash/get"

export default function BackToTopArrow({ action }) {
  const { scrollY } = useViewportScroll()
  const opacity = useTransform(scrollY, (value) => (value > 400 ? 1 : 0))
  const pointerEvents = useTransform(scrollY, (value) =>
    value > 400 ? "auto" : "none"
  )
  return (
    <motion.div
      style={{
        opacity,
        pointerEvents,
      }}
      onClick={() => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        })
      }}
      className="fixed transition-opacity duration-300  z-40 bottom-8 right-8 flex items-center  "
    >
      {get(action, "raw.length") ? (
        <div className="bg-deep-blue text-white bg-opacity-50 pr-4 rounded-l pl-3 py-1 font-semibold">
          <TextRender text={action.raw} />
        </div>
      ) : null}{" "}
      <div className="  cursor-pointer   rounded bg-primary bg-opacity-75 text-white p-4 text-xl shadow">
        <MdArrowUpward />
      </div>
    </motion.div>
  )
}
