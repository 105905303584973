import React from "react"
import { graphql, Link } from "gatsby"
import get from "lodash/get"
import { css } from "@emotion/core"
import tw from "twin.macro"
import { MdKeyboardArrowRight } from "react-icons/md"
import Layout from "../components/Layout"

import TextRender from "../components/TextRender"
import Curve from "../components/Curve"
import { linkResolver } from "../../prismic.config"
import kebabCase from "lodash/kebabCase"
import CtaText from "../components/slices/CtaText"
import Accordion from "../components/slices/Accordion"
import Text from "../components/slices/Text"
import RegistrationCtaSection from "../components/slices/RegistrationCtaSection"
import Related from "../components/slices/Related"
import ImageResolver from "../components/ImageResolver"

export default function DeepDive({ data, pageContext }) {
  const post = data.post.data

  const featured_image = get(post, "featured_image")
  if (!post) {
    return null
  }
  return (
    <Layout pageContext={pageContext} seo={get(data, "prismicPost.seo")}>
      <div className="pt-40 pb-20 phone:pt-32 bg-light ">
        <div
          className="mx-auto prose text-center"
          css={css`
            h1 {
              ${tw`text-2xl`}
            }
          `}
        >
          <TextRender text={get(post, "title.raw")} />
          <div className="text-black">
            {" "}
            <TextRender text={get(post, "author.raw")} />
          </div>
        </div>
      </div>
      <Curve className="text-white" />
      <div className="container relative z-10 -mt-5 text-center">
        <div className="inline-flex flex-wrap items-center justify-center px-6 py-2 text-sm font-semibold bg-white rounded text-light-text phone:px-3 filter-shadow">
          <Link
            className="text-primary"
            to={linkResolver(get(post, "parent.document"))}
          >
            {get(post, "parent.document.data.title.text")}
          </Link>{" "}
          <MdKeyboardArrowRight />{" "}
          <span className="text-primary">{get(post, "title.text")}</span>
        </div>
      </div>

      <div className="container py-20">
        <div className="relative items-start row phone:space-y-6 tablet:flex-col-reverse tablet:space-y-6 tablet:space-y-reverse">
          <div className="w-full col lg:w-1/2">
            <div className="prose">
              {" "}
              <TextRender text={get(post, "intro.raw")} />
              <h4>{get(post, "table_of_content")}</h4>
            </div>
            <RenderTOC body={post.body} />
          </div>
          <div className="w-full col lg:w-1/2">
            <div className="overflow-hidden ">
              {featured_image && <ImageResolver image={featured_image} />}
            </div>
          </div>
        </div>
      </div>
      <div className=" pt-1 pb-4">
        {" "}
        {get(post, "body[0]", []) &&
          post.body.map((slice, index) => {
            switch (slice.__typename) {
              case "PrismicDeepDiveBodyCtaText":
                return (
                  <CtaText key={index} data={slice} lang={pageContext.lang} />
                )
              case "PrismicDeepDiveBodyAccordion":
                return <Accordion key={index} data={slice} />
              case "PrismicDeepDiveBodyText":
                return <Text key={index} data={slice} />
              case "PrismicDeepDiveBodyRegistrationCtaSection":
                return <RegistrationCtaSection key={index} data={slice} />
              case "PrismicDeepDiveBodyRelated":
                return (
                  <Related key={index} data={slice} pageContext={pageContext} />
                )
              default:
                break
            }
          })}
      </div>
    </Layout>
  )
}

export function getTitleFromRaw(raw, asID) {
  const h2 = raw.find((i) => i.type == "heading2")
  const h3 = raw.find((i) => i.type == "heading3")
  const text = h2 ? h2.text : h3 ? h3.text : ""
  return asID ? kebabCase(text) : text
}
function RenderTOC({ body }) {
  const titles = React.useMemo(() => {
    return body.map((slice, index) => {
      switch (slice.__typename) {
        case "PrismicDeepDiveBodyCtaText":
          return //getCtaTextId(get(slice, "primary.text.raw"))
        case "PrismicDeepDiveBodyAccordion":
          return get(slice, "primary.title1.text")
        case "PrismicDeepDiveBodyText":
          return getTitleFromRaw(get(slice, "primary.text.raw"), false)
        case "PrismicDeepDiveBodyRegistrationCtaSection":
          return getTitleFromRaw(get(slice, "primary.text.raw"))
        case "PrismicDeepDiveBodyRelated":
          return get(slice, "primary.title")
        default:
          break
      }
    })
  }, [])
  return (
    <>
      {titles.filter(Boolean).map((item, index) => (
        <a
          className="block text-primary my-1"
          key={index}
          href={`#${kebabCase(item)}`}
          onClick={(e) => {
            e.preventDefault()
            const el = document.getElementById(kebabCase(item))
            if (el) {
              window.scrollTo({ top: el.offsetTop - 150, behavior: "smooth" })
            }
          }}
        >
          {index + 1}. {item}
        </a>
      ))}
    </>
  )
}

export const query = graphql`
  query($uid: String!, $lang: String) {
    post: prismicDeepDive(uid: { eq: $uid }, lang: { eq: $lang }) {
      uid
      seo: data {
        meta_title
        meta_description
        meta_keywords
        meta_image {
          url
        }
      }
      data {
        title {
          html
          text
          raw
        }
        author {
          raw
        }
        date(formatString: "Do MMMM, yyyy", locale: $lang)
        featured_image {
          alt
          url
          dimensions {
            width
            height
          }
          fluid(maxWidth: 900, imgixParams: { maxW: 900 }) {
            ...GatsbyPrismicImageFluid_noBase64
          }
        }
        intro {
          raw
        }
        table_of_content
        parent {
          document {
            ... on PrismicPage {
              id
              uid
              type
              lang
              data {
                title {
                  text
                }
              }
            }
          }
        }
        body {
          ... on PrismicDeepDiveBodyCtaText {
            primary {
              text {
                raw
              }
            }
            slice_label
          }
          ... on PrismicDeepDiveBodyText {
            slice_label
            id
            primary {
              variation
              background_highlight
              text {
                raw
              }
            }
          }
          ... on PrismicDeepDiveBodyAccordion {
            slice_label
            id
            primary {
              title1 {
                text
              }
            }
            items {
              title1
              text {
                raw
              }
            }
          }
          ... on PrismicDeepDiveBodyRegistrationCtaSection {
            slice_label
            id
            items {
              logos {
                dimensions {
                  width
                  height
                }
                url
                fluid(maxWidth: 140, imgixParams: { maxW: 140 }) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
              }
            }
            primary {
              text {
                raw
              }
              logos_text {
                raw
              }
              variation
              cta {
                document {
                  ... on PrismicCallToAction {
                    id
                    data {
                      options {
                        sidebar_link {
                          link_type
                          document {
                            ... on PrismicPage {
                              id
                              uid
                              type
                              lang
                              url
                            }
                          }
                          target
                          url
                        }
                        image {
                          fluid(maxWidth: 500, imgixParams: { maxW: 500 }) {
                            ...GatsbyPrismicImageFluid_noBase64
                          }
                          url
                          alt
                          dimensions {
                            height
                            width
                          }
                        }
                        content {
                          raw
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicDeepDiveBodyRelated {
            id
            primary {
              as_cards
              title: title1
              explore_more {
                raw
              }
            }
            slice_label
            items {
              dives {
                document {
                  ... on PrismicPost {
                    id
                    uid
                    type
                    lang
                    data {
                      title {
                        text
                      }
                      featured_image {
                        alt
                        url
                        dimensions {
                          width
                          height
                        }
                        fluid(maxWidth: 100) {
                          ...GatsbyPrismicImageFluid_noBase64
                        }
                      }
                      text {
                        excerpt
                      }
                    }
                  }
                  ... on PrismicDeepDive {
                    id
                    uid
                    type
                    lang
                    data {
                      title {
                        text
                      }
                      featured_image {
                        alt
                        url
                        dimensions {
                          width
                          height
                        }
                        fluid(maxWidth: 100) {
                          ...GatsbyPrismicImageFluid_noBase64
                        }
                      }
                      text: intro {
                        excerpt
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
