import React from "react"
import GatsbyImage from "gatsby-image"

import ImageProps from "./ImageProps"

export default function ImageResolver({
  image,
  dimensions,
  className,
  style,
  width,
  height,
  loading,
  objectFit,
}) {
  let imageDimensions = dimensions || image.dimensions
  let extension = image.url?.match(/\.([^\./\?]+)($|\?)/)[1]
  let loadStrategy = loading || "lazy"

  return (
    <>
      {extension === "svg" ? (
        <img
          src={image.url}
          loading={loadStrategy}
          alt={image.alt}
          style={style}
          width={width || imageDimensions.width}
          height={height || imageDimensions.height}
          className={className}
          objectFit={objectFit}
        />
      ) : (
        <GatsbyImage
          {...ImageProps(image)}
          fluid={image.fluid}
          loading={loadStrategy}
          style={style}
          className={className}
          objectFit={objectFit}
        />
      )}
    </>
  )
}
