import React from "react"
import { css } from "@emotion/core"
import cn from "classnames"
export default function Curve({ className = "" }) {
  return (
    <div className="relative">
      <div
        className={cn("  overflow-hidden  ", className)}
        css={css`
          position: absolute;
          pointer-events: none;
          right: 0;
          bottom: -2px;
          left: 0;
        `}
      >
        <svg
          viewBox="0 0 2880 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          css={css`
            transform: scale(2);
            width: 100%;
            height: auto;

            transform-origin: top center;
          `}
        >
          <path
            d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
            fill="currentColor"
          />
        </svg>
      </div>
    </div>
  )
}
