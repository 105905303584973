/* eslint-disable */

import { motion } from "framer-motion"
import React from "react"

export default function Collapse({ isOpen, initialyOpen, children }) {
  const variants = {
    open: {
      height: "auto",
    },
    closed: {
      height: 0,
    },
  }
  return (
    <motion.div
      className="overflow-hidden"
      variants={variants}
      initial={initialyOpen ? "open" : "closed"}
      animate={isOpen ? "open" : "closed"}
      transition={{
        stiffness: 200,
        damping: 200,
      }}
    >
      {children}
    </motion.div>
  )
}
