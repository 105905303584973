import React from "react"
import TextRender from "../TextRender"
import get from "lodash/get"
import { css } from "@emotion/core"
import tw from "twin.macro"
import { Link } from "gatsby"
import { linkResolver } from "../../../prismic.config"
import ImageResolver from "../ImageResolver"

export default function CtaCards(props) {
  return (
    <div
      className="row justify-center"
      css={css`
        > * {
          ${tw` my-4`}
        }
      `}
    >
      {" "}
      {props.items &&
        props.items.map((item, index) => {
          const Tag =
            get(item, "sidebar_link.link_type") == "Document" ? Link : "a"
          const attrs =
            get(item, "sidebar_link.link_type") == "Document"
              ? {
                  to: linkResolver(get(item, "sidebar_link.document")),
                }
              : {
                  href: get(item, "sidebar_link.url"),
                  target: get(item, "sidebar_link.target"),
                }
          return (
            <div
              className={props.columns || "col w-full md:w-1/2 lg:w-1/4 px-3"}
            >
              <Tag
                {...attrs}
                activeClassName={props.highlightActive && "active"}
                key={index}
                className="c grid gap-4 bg-white  items-stretch   w-full  border border-transparent hover:border-primary      group filter-shadow rounded p-4       "
                css={css`
                  ${props.highlightActive && tw`bg-light`}
                  &.active {
                    ${tw`border-primary bg-white`}
                  }
                  grid-template-columns: 40px 1fr;
                `}
              >
                <div
                  className="relative mt-2 group w-12 mx-auto flex items-center justify-center"
                  css={css`
                    * {
                      transition: all 300ms;
                    }
                  `}
                >
                  <div
                    className="absolute z-0  transform -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2  -rotate-140"
                    css={css`
                      width: calc(40px * 0.9);
                      height: calc(40px * 0.9);
                    `}
                  >
                    {" "}
                    <div
                      className="  "
                      css={css`
                        width: calc(40px * 0.9);
                        height: calc(40px * 0.9);
                        transform: rotate(0deg);
                        /* .group:hover & {
                          animation: spinning 7s linear infinite;
                          background: rgba(25, 188, 21, 0.336);
                        } */
                        @keyframes spinning {
                          from {
                            transform: rotate(0deg);
                          }
                          to {
                            transform: rotate(360deg);
                          }
                        }
                        background: linear-gradient(
                          180deg,
                          rgba(255, 255, 255, 0.7) 0%,
                          rgba(206, 241, 205, 0.7) 58.68%,
                          rgba(25, 188, 21, 0.336) 100%
                        );
                      `}
                    ></div>
                  </div>
                  <div className="relative z-8 w-8 ">
                    <ImageResolver image={item.image} />
                  </div>
                </div>
                <div
                  className="prose"
                  css={css`
                    h3,
                    strong {
                      ${tw`text-base mb-0.5`}
                    }
                    p {
                      ${tw`text-sm!`}
                    }
                  `}
                >
                  <TextRender text={item.content.raw} />
                </div>
              </Tag>
            </div>
          )
        })}
    </div>
  )
}
