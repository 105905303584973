import { RichText, Elements } from "prismic-reactjs"
import React, { useEffect } from "react"
import Typed from "react-typed"
import "react-typed/dist/animatedCursor.css"
import { css } from "@emotion/core"
import tw from "twin.macro"
import { linkResolver } from "../../prismic.config"
import get from "lodash/get"

import { Link } from "gatsby"
import { FaArrowRight, FaCheck, FaCheckCircle } from "react-icons/fa"
import isFunction from "lodash/isFunction"
import CtaText from "./slices/CtaText"
export default function TextRender({ ctas, text = {}, type, serializer }) {
  return (
    <RichText
      render={text}
      htmlSerializer={htmlSerializer(type, serializer, ctas)}
      linkResolver={linkResolver}
    />
  )
}

const htmlSerializer = (variation, serializer, ctas) =>
  function (type, element, content, children, key) {
    if (variation == "custom") {
      isFunction(serializer) &&
        serializer(type, element, content, children, key)
    }
    if (variation == "cluster") {
      switch (type) {
        case Elements.listItem:
          return (
            <li key={key} className="relative">
              <FaArrowRight
                css={css`
                  ${tw`absolute bg-white text-primary top-1.5 left-0 my-0!`}
                `}
              />{" "}
              {children}
            </li>
          )

        default:
          break
      }
    }
    if (variation == "navigation") {
      switch (type) {
        case Elements.hyperlink:
          return (
            <LinkResolver data={element.data} key={key}>
              {children}
            </LinkResolver>
          )
        case Elements.paragraph:
          return (
            <span
              className="top-item hover:text-primary tablet:text-xl"
              key={key}
            >
              {children}
            </span>
          )

        default:
          break
      }
    }
    if (variation == "submenu") {
      switch (type) {
        case Elements.listItem:
          const innerLink = element.spans.find(
            (item) => item.type == "hyperlink"
          )["data"]

          return (
            <li key={key} className="text-xl">
              <LinkResolver skip_modified data={innerLink}>
                {children}
              </LinkResolver>
            </li>
          )
        case Elements.strong:
          return (
            <strong key={key} className="text-xl">
              {children}
            </strong>
          )
        case Elements.hyperlink:
          return (
            <React.Fragment key={key}>
              <h5 className="font-semibold ">{children}</h5>
            </React.Fragment>
          )
        case Elements.em: {
          return (
            <div className="text-light-text" key={key}>
              {children}
            </div>
          )
        }
        case Elements.paragraph:
          if (element.text.match(/---/)) {
            return <div className="w-full h-0 lg:h-full lg:w-0"></div>
          }
          return <React.Fragment key={key}>{children}</React.Fragment>

        default:
          break
      }
    }
    switch (type) {
      case Elements.preformatted:
        return <blockquote key={key}> {children} </blockquote>
      case Elements.paragraph:
        if (element.text.match(/---/)) {
          return <hr tw="my-4!" />
        }

        if (
          element.text.match(/\[cookiebot\=\"(?<url>.+)\" lang=\"(?<lang>.+)\"/)
        ) {
          const m = element.text.match(
            /\[cookiebot\=\"(?<url>.+)\" lang=\"(?<lang>.+)\"/
          )
          return m && <CookieData url={m[1]} lang={m[2]} />
        }

        if (element.text.match(/\[CTA\=\"(?<id>.+)\"/)) {
          const m = element.text.match(/\[CTA\=\"(?<id>.+)\"/)
          const id = get(m, "groups.id")
          if (!id) {
            return
          }
          return (
            <div
              css={css`
                > div {
                  ${tw`px-6 py-8 my-0`}
                }
              `}
            >
              <CtaText
                data={{
                  slice_label: "",
                  primary: {
                    text: {
                      raw: ctas
                        ? get(
                            ctas.find((c) => c.id === id),
                            "text.raw"
                          )
                        : [],
                    },
                  },
                }}
              />
            </div>
          )
        }
        break

      case Elements.hyperlink:
        return (
          <LinkResolver data={element.data} key={key}>
            {children}
          </LinkResolver>
        )
      case Elements.image:
        let title = element.alt && element.alt.match(/title\=\"(?<title>.+)\"/)
        function isSVG() {
          return element.url.endsWith(".svg")
        }
        function elUrlCheck(element) {
          return (
            element +
            (element.indexOf("?") === -1 ? "?auto=compress,format" : "")
          )
        }
        return (
          <p className=" block-img" key={key}>
            {isSVG() ? (
              <img
                src={element.url}
                alt={replaceText(element.alt)}
                title={title && title[1]}
              />
            ) : (
              <div
                css={css`
                  ${tw`relative overflow-hidden`}
                  img {
                    ${tw`absolute w-full h-full inset-0 my-0!`}
                  }
                  padding-bottom: calc(
                    ${get(element, "dimensions.height", 1)} /
                      ${get(element, "dimensions.width", 1)} * 100%
                  );
                `}
              >
                <img
                  src={elUrlCheck(element.url) + "&w=800&q=90&maxW=800"}
                  srcset={`${elUrlCheck(
                    element.url
                  )}&q=90&w=200&h=117&maxW=800 200w, ${elUrlCheck(
                    element.url
                  )}&q=90&w=400&h=234&maxW=800 400w, ${elUrlCheck(
                    element.url
                  )}&q=90&w=800&h=467&maxW=800 800w, ${elUrlCheck(
                    element.url
                  )}&q=90&w=1200&h=701 1200w, ${elUrlCheck(
                    element.url
                  )}&q=90&w=1600&h=935&maxW=800 1600w`}
                  alt={replaceText(element.alt)}
                  title={title && title[1]}
                />
              </div>
            )}
          </p>
        )
        break
      case Elements.label:
        switch (element.data.label) {
          case "animated-text":
            return (
              <span
                className="text-primary phone:block"
                css={css`
                  .typed-cursor {
                    ${tw`relative -top-0.5`}
                  }
                `}
                key={key}
              >
                <Typed
                  strings={content.split(",")}
                  typeSpeed={50}
                  backSpeed={50}
                  backDelay={2000}
                  loop
                ></Typed>
              </span>
            )
          case "check-icon":
            return (
              <React.Fragment key={key}>
                <FaCheck
                  css={css`
                    ${tw`absolute top-1.5 left-0 my-0!   text-primary`}
                    li:before {
                      display: none;
                    }
                  `}
                />{" "}
                {children}
              </React.Fragment>
            )
          case "check-icon-circle":
            return (
              <React.Fragment key={key}>
                <FaCheckCircle
                  css={css`
                    ${tw`absolute top-1.5 left-0 my-0! bg-white text-primary`}
                  `}
                />{" "}
                {children}
              </React.Fragment>
            )
          case "spacer":
            return <div key={key} className="lg:h-10"></div>

          default:
            break
        }
      default:
        break
    }
    return (
      isFunction(serializer) &&
      serializer(type, element, content, children, key)
    )
  }

function CookieData({ url, lang }) {
  useEffect(() => {
    if (typeof window !== "undefined") {
      let script = document.createElement("script")
      script.src = url
      script.async = true
      script.dataset["culture"] = lang
      script.id = "CookieDeclaration"
      document.getElementById("cookie-data").appendChild(script)
    }
  }, [])
  return <div id="cookie-data"></div>
}

export function LinkResolver({ data, children, skip_modified }) {
  var [attrs, modified] = parse(children)

  switch (data.link_type) {
    case "Document":
      return (
        <Link to={linkResolver(data)} {...attrs}>
          {(!skip_modified && modified) || children}
        </Link>
      )
    case "Web":
      let url = data.url

      if (url.match(/\/\/#/)) {
        url = url.replace("https://#", "#")

        return (
          <Link to={url} {...attrs}>
            {(!skip_modified && modified) || children}
          </Link>
        )
      }
      if (url.match(/\/\/\//)) {
        url = url.replace("https:///", "/")

        return (
          <Link to={url} {...attrs}>
            {(!skip_modified && modified) || children}
          </Link>
        )
      }

      return (
        <a href={url} target={data.target} {...attrs}>
          {(!skip_modified && modified) || children}
        </a>
      )
    default:
      break
  }
  return null
}

function parse(children) {
  const attrs = {}
  let modified = null
  try {
    let [child] = React.Children.toArray(children)
    if (typeof child === "string") {
      setAttributes(child, attrs)
      modified = replaceText(child)
    } else if (typeof child === "object") {
      if (typeof child.props.children === "string") {
        setAttributes(child.props.children, attrs)
        attrs["className"] = child.props.className
        modified = replaceText(child.props.children)
      } else {
        let [first] = React.Children.toArray(child.props.children)

        attrs["className"] = child.props.className
        setAttributes(first, attrs)
        modified = replaceText(first)
      }
    }
  } catch (error) {}
  return [attrs, modified]
}

function setAttributes(item, attrs) {
  const a = item.match(/(?<attrs>(\w)+\=\"(\w|\s)+\")/g)

  a &&
    a.forEach((attr) => {
      const m = attr.match(/(?<key>(\w)+)\=\"(?<value>(\w|\s)+)\"/)
      if (!m.groups) {
        return
      }

      attrs[m.groups.key] = m.groups.value
    })
}
function replaceText(item) {
  return item && item.replace(/\[.+\]/, "")
}
