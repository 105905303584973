import React from "react"
import { Link } from "gatsby"
import { MdKeyboardArrowRight } from "react-icons/md"
import get from "lodash/get"
import { css } from "@emotion/core"
import cn from "classnames"
import tw from "twin.macro"
import kebabCase from "lodash/kebabCase"

import useLabels from "../useLabels"
import { linkResolver } from "../../../prismic.config"
import TextRender from "../TextRender"
import ImageResolver from "../ImageResolver"

export default function Related({ data, pageContext }) {
  const labels = useLabels(pageContext.lang)
  const asCards = data.primary.as_cards
  const hasExploreMore =
    get(data, "primary.explore_more.raw[0].text", "").trim().length > 0
  return (
    <div className="container my-16" id={kebabCase(get(data, "primary.title"))}>
      <div className=" mt-10 ">
        <div className="">
          <h3 className="text-2xl mb-10">{get(data, "primary.title")}</h3>
        </div>
        <div
          className={cn("grid gap-6 mt-6 ", {
            "md:grid-cols-3": asCards,
          })}
        >
          {data.items &&
            data.items.map((dive, index) => {
              const post = get(dive, "dives.document")

              const image = get(post, "data.featured_image")
              return (
                <div key={index} className="border rounded-md overflow-hidden">
                  <div className="row md:items-center py-0">
                    <div
                      className={cn("col", {
                        " w-full sm:w-48": !asCards,
                        "w-full": asCards,
                      })}
                    >
                      <Link
                        to={
                          post &&
                          linkResolver({
                            type: post.type,
                            lang: pageContext.lang,
                            uid: post.uid,
                          })
                        }
                      >
                        <div
                          style={{ height: "100%", minHeight: 150 }}
                          css={css`
                            > div {
                              height: 100%;
                              min-height: 150px;
                            }
                          `}
                        >
                          {image && <ImageResolver image={image} />}
                        </div>
                      </Link>
                    </div>
                    <div
                      className={cn("col", {
                        " w-full   py-4": !asCards,
                        " w-full   py-4": asCards,
                      })}
                      css={css`
                        ${tw`px-8!`}
                        @media (min-width: 640px) {
                          width: ${asCards ? "100%" : "calc(100% - 12rem);"};
                        }
                      `}
                    >
                      <h3>{get(post, "data.title.text", "")}</h3>
                      <div className="prose tablet:prose-tablet">
                        <p>{get(post, "data.text.excerpt")}</p>
                        <p tw="text-sm! -mt-3! opacity-80! ">
                          {get(post, "data.date")}
                        </p>
                      </div>
                      <Link
                        className="text-primary inline-flex items-center text-sm  "
                        to={
                          post &&
                          linkResolver({
                            type: post.type,
                            lang: pageContext.lang,
                            uid: post.uid,
                          })
                        }
                      >
                        {labels.read_more} <MdKeyboardArrowRight />
                      </Link>
                    </div>
                  </div>
                </div>
              )
            })}
        </div>

        {hasExploreMore && (
          <div
            className="  max-w-none text-center pt-16 mb-4"
            css={css`
              a {
                min-width: 100px;
                ${tw`inline-flex items-center justify-center px-3 py-2 leading-snug text-lg bg-white border rounded-sm text-primary border-primary`}
                color: #19bc15 !important;

                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);

                min-width: 100px;
                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
              }
            `}
          >
            <TextRender text={data.primary.explore_more.raw}></TextRender>
          </div>
        )}
      </div>
    </div>
  )
}
