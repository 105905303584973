import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import get from "lodash/get"
import { css } from "@emotion/core"
import tw from "twin.macro"

import Curve from "./Curve"
import TextRender from "./TextRender"
import { FaFacebook, FaLinkedin, FaTwitter } from "react-icons/fa"
import GoogleOff from "./GoogleOff"

export default function Footer({ pageContext }) {
  const { configurations } = useStaticQuery(graphql`
    {
      configurations: allPrismicConfiguration {
        nodes {
          lang
          data {
            text {
              raw
            }
            linkedin_link
            twitter_link
            facebook_link
            columns {
              nav: navigation1 {
                raw
              }
            }
          }
        }
      }
    }
  `)
  const configuration = configurations.nodes.find(
    (n) => n && pageContext && n.lang == pageContext.lang
  )
  return (
    <>
      <Curve className="text-deep-blue" />
      <footer className="bg-deep-blue">
        <div className="container py-20 text-light-text">
          <div
            className="row"
            css={css`
              h3 {
                ${tw`mb-4 text-lg text-white`}
              }
            `}
          >
            <div className="w-full col lg:w-2/6 tablet:mb-8">
              <GoogleOff>
                <TextRender text={get(configuration, "data.text.raw")} />
              </GoogleOff>
              <div className="flex mt-6 space-x-5 text-2xl">
                <a href={get(configuration, "data.linkedin_link")}>
                  <FaLinkedin />
                  <span className="sr-only">Facebook</span>
                </a>
                <a href={get(configuration, "data.facebook_link")}>
                  <FaFacebook />
                  <span className="sr-only">Facebook</span>
                </a>
                <a href={get(configuration, "data.twitter_link")}>
                  <FaTwitter />
                  <span className="sr-only">Facebook</span>
                </a>
              </div>
            </div>
            {configuration.data.columns.map(({ nav }) => {
              return (
                <div
                  className="w-full col md:w-1/3 lg:w-1/6 mb-6"
                  css={css`
                    a {
                      ${tw`hover:text-primary`}
                    }
                  `}
                >
                  <TextRender text={get(nav, "raw")} />
                </div>
              )
            })}
          </div>
        </div>
        <div className="border-t border-white border-opacity-10">
          <div className="container p-8 text-xs text-light-text">
            © 2011 - {new Date().getFullYear()}{" "}
            <span className="pl-1">greatcontent GmbH</span>
          </div>
        </div>
      </footer>
    </>
  )
}
