import React from "react"
import get from "lodash/get"
import { css } from "@emotion/core"
import CtaCards from "./CtaCards"
import tw from "twin.macro"

import TextRender from "../TextRender"
import { getTitleFromRaw } from "../../templates/deep-dive"
import ImageResolver from "../ImageResolver"

export default function RegistrationCtaSection({ data }) {
  const items = get(data, "primary.cta.document.data.options")
  const variation = get(data, "primary.variation")

  return (
    <>
      <div
        className="pt-16 pb-24 bg-light label-id"
        id={getTitleFromRaw(get(data, "primary.text.raw"), true)}
        data-label={data.slice_label}
      >
        <div className="container">
          <div className="mx-auto prose text-center">
            <TextRender text={get(data, "primary.text.raw")} />
          </div>
        </div>
        <div className="lg:max-w-7xl container my-8 md:my-12">
          <CtaCards items={items}></CtaCards>
        </div>
        <hr />
        {variation === "With Logo Text" ? (
          <div className="container phone:mt-4 pb-6 pt-16  ">
            {" "}
            <div
              className="text-center mb-6"
              css={css`
                p {
                  ${tw`text-lg text-black`}
                }
              `}
            >
              {" "}
              <TextRender text={get(data, "primary.logos_text.raw")} />
            </div>
            {data.items && (
              <div className="flex flex-wrap items-center   justify-center">
                {data.items.map(({ logos }) => {
                  if (!logos.fluid) {
                    return null
                  }
                  const maxWidth =
                    logos.dimensions.width > 75 ? 75 : logos.dimensions.width
                  return (
                    <div
                      className="w-full mx-4  my-3 md:my-4"
                      css={css`
                        max-width: ${maxWidth / 1.5}px;
                        @media (min-width: 1024px) {
                          max-width: ${maxWidth}px;
                        }
                      `}
                    >
                      <ImageResolver image={logos} />
                    </div>
                  )
                })}
              </div>
            )}
          </div>
        ) : (
          <div className="container">
            <div className="flex flex-wrap justify-center  items-center py-5">
              {data.items &&
                data.items.map(({ logos }) => {
                  return (
                    logos && (
                      <div
                        className="w-full mx-8 my-4"
                        style={{
                          maxWidth:
                            get(logos, "dimensions.width") > 115
                              ? 115
                              : get(logos, "dimensions.width"),
                        }}
                      >
                        <ImageResolver image={logos} />
                      </div>
                    )
                  )
                })}
            </div>
          </div>
        )}
      </div>
      {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
    </>
  )
}
