import React from "react"
import get from "lodash/get"
import cn from "classnames"
import TextRender from "../TextRender"
import { css } from "@emotion/core"
import { getTitleFromRaw } from "../../templates/deep-dive"

export default function Text({ data }) {
  return (
    <div
      className={cn(" label-id", {
        "bg-light py-12": get(data, "primary.background_highlight"),
        "my-12": !get(data, "primary.background_highlight"),
      })}
      id={getTitleFromRaw(get(data, "primary.text.raw"), true)}
      data-label={data.slice_label}
    >
      <div className="container">
        <div
          css={css`
            & ol > li:before {
              color: #8492a6;
            }
            & ul > li:before {
              background-color: #8492a6;
            }
          `}
          className={cn("prose tablet:prose-tablet", {
            "max-w-none": "Legal" !== get(data, "primary.variation"),
            "mx-auto max-w-xl": "Legal" == get(data, "primary.variation"),
          })}
        >
          <TextRender text={get(data, "primary.text.raw")} />
        </div>
      </div>
    </div>
  )
}
