import get from "lodash/get"

export default function customEvent(e) {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: "buttonClick",
    location: getLocation(e),
    cta: get(e, "target.innerText"),
    link: getLink(e),
  })
}

function getLink(e) {
  return e.target.href
}

function getLocation(e) {
  const isHeader = Boolean(e.target.closest("header"))
  const isFooter = Boolean(e.target.closest("footer"))
  if (isHeader) {
    return "header"
  } else if (isFooter) {
    return "footer"
  } else {
    const el = e.target.closest(".label-id")
    let label = "main"
    if (el && el.dataset.label) {
      label = el.dataset.label
    }
    return label
  }
}
